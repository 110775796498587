import React from 'react'
import Requirement from './img/requirement.png'
import Connect from './img/connect.png'
import design from './img/interior-design.png'
import spaceReady from './img/goal.png'
import pVisa from './img/g-visa.png'
import arrow from './img/arrow.png'

export default function HowWeWork() {
  return (
    <section className="how-we-work  py-5">
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-md-6 text-center">
                    <button  data-aos="fade-up" className="btn btn-primary px-3 py-1 mb-3">How We Work</button>
                    <h2 data-aos="fade-up" className="fw-bold text-dark">How To Transform Your Space</h2>
                    <p data-aos="fade-up" className='text-dark'>"Steps to redesign and optimize your space for better style and practicality.</p>
                </div>
            </div>

            <div className="row pt-5 position-relative">
                <div className="col-md-3 px-5 col-6">
                    <img data-aos="fade-up" src={Requirement} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Requirement Gathering</h6>
                    <p data-aos="fade-up" className='text-dark'>Collecting detailed user needs for project planning and execution.</p>
                </div>
                <img data-aos="fade-up" src={arrow} alt="img" className="arrowH1" />

                <div className="col-md-3 px-5 mt-5 pt-5 col-6">
                    <img data-aos="fade-up" src={Connect} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Connect With Us</h6>
                    <p data-aos="fade-up" className='text-dark'>Collaborating to share ideas and insights for project execution.</p>
                </div>

                <div className="col-md-3 px-5 col-6">
                    <img data-aos="fade-up" src={design} alt="" className="img-fluid" />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Design & Renovation</h6>
                    <p data-aos="fade-up" className='text-dark'>Creating and upgrading designs for improved functionality and aesthetics.</p>
                </div>
                <img data-aos="fade-up" src={arrow} alt="img" className="arrowH2" />

                <div  className="col-md-3 px-5 mt-5 pt-5 col-6">
                    <img data-aos="fade-up" src={spaceReady} alt="" className="img-fluid " />
                    <h6 data-aos="fade-up" className='primary-text mt-3'>Get Your Space Ready</h6>
                    <p  data-aos="fade-up" className='text-dark'>Preparing spaces efficiently for functionality, comfort, and usability.</p>
                </div>


            </div>
        </div>
    </section>
  )
}
