import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import popup from './img/Transfer-Your-Space-with-A3G.jpg';


import logo from './img/logo.png'
import logo1 from './img/A3G-logo.png'
import email from './img/email.png'
import { Link } from 'react-router-dom'
import twitter from './img/twitter.png'
import insta from './img/instagram.png'
import face from './img/facebook.png'
import linked from './img/linkedin.png'
import icon from './img/live-chat.png'
import footerLogo from './img/logo-round-360.png'

const PopupForm = () => {
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const [category, setCategory] = useState(""); // Track category selection
  const [estimate, setEstimate] = useState(""); // Track estimate selection
  const [whatsappConsent, setWhatsappConsent] = useState(false); // Track checkbox
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    category: "",
    estimate: "",
    additionalInfo: "",
    whatsappConsent: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true); // Automatically show the modal after 5 seconds
    }, 5000); // Adjust to 10000 for 10 seconds

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const handleClose = () => setShowModal(false); // Close the modal
  

  const handleOpen = () => setShowModal(true); // Open the modal manually
  const handleCheckboxChange = () => setWhatsappConsent(!whatsappConsent); // Toggle checkbox state

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form submission for validation

    if (!whatsappConsent) {
      alert("Please provide your consent for WhatsApp updates.");
      return; // Stop form submission if consent is not given
    }

    

    
    
  };

  

  

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setEstimate(""); // Reset estimate selection when category changes
  };

  const handleEstimateChange = (e) => setEstimate(e.target.value); // Update estimate selection



  return (
    <>
      <footer className='bg-white border-top'>
        <div className="container pt-5">
          <div className="row border-secondary align-items-center border-bottom pb-3">
            <div className="col-md-3">
              <a className='d-flex align-items-center' href='/'>
                <img  src={logo1} alt="logoImage" className="img-fluid logo1" />
                <img  src={logo} alt="logoImage" className="img-fluid logo-f py-4" />
              </a>
            </div>
            <div className="col-md-4 mpy-4">
              <h4 className='text-dark mb-0 ps-5 pb-0'>Subscribe Newsletter</h4>
            </div>
            <div className="col-md-5">
              <div className="row subscribe bg-white p-2">
                <div className="col-7  col-md-9">
                  <div className="input-group border">
                    <span className="input-group-text" id="email">
                      <img src={email} alt="email" className="img-fluid" />
                    </span>
                    <input type="email" autoComplete='off' name='email' className="form-control border-0 " placeholder="Email" aria-label="email" aria-describedby="email" />
                  </div>
                </div>
                <div className='col-5 col-md-3'>
                  <button name='submit' className='btn btn-primary border-0'>Subscribe&nbsp;<span className=''>&#8250;</span></button>
                </div>
                
                
              </div>
            </div>
          </div>
          <div className='row py-4'>
            <div className="col-md-5">              
              <h5>  A unit of</h5>
              <img src={footerLogo} className='img-fluid unit-logo rounded-circle mb-3' />
              <p className='text-secondary'>At A3G Interio, we believe every space tells a story, and we are dedicated to crafting spaces that are nothing short of timeless masterpieces. From the intimate warmth of a cozy home to the vibrant energy of a dynamic commercial setup, we are committed to turning your vision into a reality.</p>
            </div>
            <div className="col-md-4">
              <h5 className='text-dark'>Quick Links</h5>
              <ul className='text-dec-none  ps-0' id='QuickLinks'>
                <li className='pb-2 pt-2'><Link className='text-secondary' to="./"><i className="bi bi-chevron-compact-right"></i>&nbsp;Home</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Services</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;About</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Blog</Link></li>
                

                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Contact</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Appointment</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Terms of Use</Link></li>
                <li className='pb-2 pt-2'><Link  className='text-secondary' to=""><i className="bi bi-chevron-compact-right"></i>&nbsp;Privacy Policy</Link></li>
              </ul>
            </div>
            <div className="col-md-3"> 
              
              <div className='row mpx-3'>
                <div className='col-md-12 p-0'>
                  <h5 className='text-dark'>Contact</h5>
                  <p className='mb-0 pb-2 text-secondary d-flex'><i className="bi bi-geo-alt-fill text-secondary "></i>H.No.8-2-622/4, Road No.10, Banjara hills, Hyderabad- 500 034, Hyderabad, Telangana 500034</p>
                  <p className='mb-0 pb-2 d-flex'><a className='text-secondary text-decoration-none' href='tel:+91 7075 90 1914'><i className="bi bi-telephone-forward"></i> +91 7075 90 1914 </a></p>
                  <p className='mb-0 pb-2 d-flex'><a className='text-secondary text-decoration-none' href='mailto: info@a3ginterio.com'><i className="bi bi-envelope"></i> info@a3ginterio.com </a></p>
                </div>
                
              </div>
            </div>
          </div>
          
        </div>
    </footer>
    <section className='bg-dark-1 footerLast'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <p className='mt-3 text-light m-text-center'>Copyright © 2024 <b className='primary-text'>A3G Interio</b> | All Rights Reserved</p>
          </div>
          <div className="col-md-4 text-end footerBottom">
                <li>
                    <a href=""><img  src={face} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={twitter} alt="" className="img-fluid bg-white" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={linked} alt="" className="img-fluid" /></a>&nbsp;&nbsp;&nbsp;&nbsp;
                    <a href=""><img  src={insta} alt="" className="img-fluid" /></a>
                </li>
          </div>
          
        </div>
      </div>
    </section>

    <div className="applyNoww">
        <div onClick={handleOpen} className="text-white  text-decoration-none">Download Prospectus</div>        
    </div>
    <div className="whatsappIcon">
        <a title='Chat Now' href="https://api.whatsapp.com/send?phone=919095949393&amp;text=Hi%20,%20I%20wanted%20to%20enquire%20about%20a%20Interior%20Services%20at%20A3G-Interio%20.%20Could%20you%20help%20me%20with%20some%20information%20?%20" className="text-white" target="_blank">
          <img src={icon} className="img-fluid" alt="whatsapp Icon" title='Chat Now' />
        </a>
    </div>





      
      

      {/* Modal */}
      <div
        className={`modal fade ${showModal ? "show d-block" : "d-none"}`}
        id="consultationModal"
        tabIndex="-1"
        aria-labelledby="consultationModalLabel"
        aria-hidden={!showModal}
        style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      >
        <div className="modal-dialog modal-custom">
          <div className="modal-content">
            <div className="container">
              <div className="row">
                <div className="col-md-6 p-0">
                  <img
                    src={popup}
                    alt="Consultation Image"
                    className="img-fluid consultation-img"
                  />
                </div>
                <div className="col-md-6 p-2 form-width">
                  <form onSubmit={handleSubmit}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <h4 className="modal-heading">
                        <b className="fw-bold">Get a </b>
                        <span>Free Design Consultation</span>
                      </h4>
                      <button
                        type="button"
                        className="close"
                        onClick={handleClose}
                        aria-label="Close"
                      >
                        &times;
                      </button>
                    </div>

                    <div>
                    {/* Top-level category selection */}
                    <label htmlFor="category">
                      <b>
                        Select Category<span className="text-danger">*</span>
                      </b>
                    </label>
                    <select
                      name="category"
                      id="category"
                      className="form-control mb-3"
                      onChange={handleCategoryChange}
                      required
                    >
                      <option value="">-- Select Category --</option>
                      <option value="residential">Residential</option>
                      <option value="commercial">Commercial</option>
                    </select>

                    {/* Conditional rendering for Estimate based on Category */}
                    {category && (
                      <>
                        <label htmlFor="estimate">
                          <b>
                            Select an option<span className="text-danger">*</span>
                          </b>
                        </label>
                        <select
                          name="estimate"
                          id="estimate"
                          className="form-control mb-3"
                          onChange={handleEstimateChange}
                          required
                        >
                          <option value="">-- Select --</option>
                          {category === "residential" && (
                            <>
                              <option value="home-interior">Home Interior</option>
                              <option value="kitchen">Kitchen</option>
                              <option value="wardrobe">Wardrobe</option>
                            </>
                          )}
                          {category === "commercial" && (
                            <>
                              <option value="office-interior">Office Interior</option>
                              <option value="restaurant">Restaurant</option>
                              <option value="retail-store">Retail Store</option>
                            </>
                          )}
                        </select>
                      </>
                    )}

                    {/* Conditional rendering based on Estimate selection */}
                    {estimate === "home-interior" && (
                      <div className="mb-3">
                        <label htmlFor="homeInterior">
                          Select BHK:<span className="text-danger">*</span>
                        </label>
                        <select name="homeInterior" id="homeInterior" className="form-control" required>
                          <option value="1bhk">1 BHK</option>
                          <option value="2bhk">2 BHK</option>
                          <option value="3bhk">3 BHK</option>
                          <option value="4bhk">4 BHK</option>
                          <option value="5bhk">5 BHK+</option>
                        </select>
                      </div>
                    )}

                    {estimate === "kitchen" && (
                      <div className="mb-3">
                        <label htmlFor="kitchen">
                          Select Kitchen Type:<span className="text-danger">*</span>
                        </label>
                        <select name="kitchen" id="kitchen" className="form-control" required>
                          <option value="l-shaped">L-shaped</option>
                          <option value="straight">Straight</option>
                          <option value="u-shaped">U-shaped</option>
                          <option value="parallel">Parallel</option>
                        </select>
                      </div>
                    )}

                    {estimate === "wardrobe" && (
                      <div className="mb-3">
                        <label htmlFor="wardrobe">
                          Select Wardrobe Size:<span className="text-danger">*</span>
                        </label>
                        <select name="wardrobe" id="wardrobe" className="form-control" required>
                          <option value="4-feet">4 Feet</option>
                          <option value="6-feet">6 Feet</option>
                          <option value="7-feet">7 Feet</option>
                          <option value="9-feet">9 Feet</option>
                        </select>
                      </div>
                    )}

                    {estimate === "office-interior" && (
                      <div className="mb-3">
                        <label htmlFor="officeInterior">
                          Select Office Area:<span className="text-danger">*</span>
                        </label>
                        <select name="officeInterior" id="officeInterior" className="form-control" required>
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </select>
                      </div>
                    )}

                    {estimate === "restaurant" && (
                      <div className="mb-3">
                        <label htmlFor="restaurantType">
                          Select Restaurant Type:<span className="text-danger">*</span>
                        </label>
                        <select name="restaurantType" id="restaurantType" className="form-control" required>
                          <option value="casual">Casual Dining</option>
                          <option value="fine-dining">Fine Dining</option>
                          <option value="fast-food">Fast Food</option>
                        </select>
                      </div>
                    )}

                    {estimate === "retail-store" && (
                      <div className="mb-3">
                        <label htmlFor="storeSize">
                          Select Store Size:<span className="text-danger">*</span>
                        </label>
                        <select name="storeSize" id="storeSize" className="form-control" required>
                          <option value="small">Small</option>
                          <option value="medium">Medium</option>
                          <option value="large">Large</option>
                        </select>
                      </div>
                    )}
                  </div>

                    <input
                      type="text"
                      name="name"
                      placeholder="Name*"
                      required
                      className="form-control mb-3 mt-3"
                      maxLength="25"
                      onKeyUp={(e) =>
                        (e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, ""))
                      }
                      autoComplete="off"
                    />
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone No*"
                      className="form-control mb-3"
                      maxLength="10"
                      minLength="10"
                      pattern="[0-9]{10}"
                      required
                      autoComplete="off"
                    />

                    <label className="checkbox-label mb-3">
                      <input
                        type="checkbox"
                        id="whatsappConsent"
                        name="whatsappConsent"
                        value="Yes, send me updates via WhatsApp"
                        onChange={handleCheckboxChange}
                      />
                      <span>
                        &nbsp;Yes, send me updates via WhatsApp{" "}
                        <img src="images/whatsapp.png" alt="" className="img-fluid" width="20px" />
                      </span>
                    </label>

                    <button type="submit" className="btn-color btn btn-primary text-white px-3 w-100">
                      Get Free Quote
                    </button>
                    <p className="pt-3">
                      By submitting this form, you agree to the{" "}
                      <a href="#" className="text-decoration-none">
                        <span className="heading">privacy policy</span>
                      </a>{" "}
                      &{" "}
                      <a href="#" className="text-decoration-none">
                        <span className="heading">terms and conditions</span>
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

export default PopupForm;
