


import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo2 from "./img/logo.png";
import logo1 from "./img/A3G-logo.png";
import twitter from "./img/twitter.png";
import insta from "./img/instagram.png";
import face from "./img/facebook.png";
import linked from "./img/linkedin.png";
import slider1icon from "./img/modern-house.png";
import slider2icon from "./img/property.png";
import slider3icon from "./img/armchair.png";
import slider4icon from "./img/consultant.png";

export default function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // For mobile menu
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // For dropdown

  // Toggle the mobile menu
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
    setIsDropdownOpen(false); // Ensure dropdown is closed
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
  };

  // Toggle the dropdown menu
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <>
      <section id="TopNavBar" className="d-none">
        <div className="container topBar">
          <div className="row py-2">
            <div className="col-md-5">
              <p className="mb-0 pb-0 text-dark">
                <i className="bi bi-geo-alt-fill text-dark"></i>&nbsp;&nbsp;Road
                No.10, Banjara hills, Hyderabad- 500 034
              </p>
            </div>

            <div className="col-md-5 ms-auto float-end text-end topHeader list-unstyled">
              <li className="m-center">
                <a href="">
                  <img
                    data-aos="fade-up"
                    src={face}
                    alt=""
                    className="img-fluid"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <img
                    data-aos="fade-up"
                    src={twitter}
                    alt=""
                    className="img-fluid"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <img
                    data-aos="fade-up"
                    src={linked}
                    alt=""
                    className="img-fluid"
                  />
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href="">
                  <img
                    data-aos="fade-up"
                    src={insta}
                    alt=""
                    className="img-fluid"
                  />
                </a>
              </li>
            </div>
          </div>
        </div>
      </section>

      <nav className="navbar navbar-expand-lg navbar-light bg-white mb-70 sticky-top">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-2">
              <a className="navbar-brand" href="/">
                <img src={logo1} className="img-fluid logo1" alt="logo" />
                <img src={logo2} className="img-fluid logo" alt="logo" />
              </a>
            </div>
            <div className="col-md-10">
              <button
                className="navbar-toggler"
                type="button"
                aria-controls="navbarScroll"
                aria-expanded={isMobileMenuOpen ? "true" : "false"}
                aria-label="Toggle navigation"
                onClick={toggleMobileMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div
                className={`collapse navbar-collapse ${
                  isMobileMenuOpen ? "show" : ""
                }`}
                id="navbarScroll"
              >
                <ul
                  className="navbar-nav ms-auto float-end text-end py-3 my-lg-0 navbar-nav-scroll"
                  id="mobileMenu"
                >
                  <li className="nav-item">
                  <span className='nav-link' >
                    <a href="/">
                      Home
                    </a>
                    </span>
                  </li>
                  <li className="nav-item">
                  <span className='nav-link' >
                    <a href="/about-us">
                      About Us
                    </a>
                    </span>
                  </li>
                  <li
                    className={`nav-item dropdown ${
                      isDropdownOpen ? "show" : ""
                    }`}
                  >
                    <span
                      className="nav-link text-dark fw-bold dropdown-toggle"
                      onClick={toggleDropdown}
                      aria-expanded={isDropdownOpen ? "true" : "false"}
                    >
                      SERVICES
                    </span>
                    {isDropdownOpen && (
                      <ul className="dropdown-menu">
                        <li>
                          <a onClick={closeDropdown}
                            href="/residential-interior-service-in-hyderabad"
                            className="dropdown-item"
                          >
                            Residential Interior
                          </a>
                        </li>
                        <li>
                          <a onClick={closeDropdown}
                            href="/commercial-interior-service-in-hyderabad"
                            className="dropdown-item"
                          >                            
                            Commercial Interior
                          </a>
                        </li>
                        <li className="d-none">
                          <a onClick={closeDropdown}
                            href="/turnkey-interior-solutions-in-hyderabad"
                            className="dropdown-item"
                          >
                            <img
                              src={slider3icon}
                              className="img-fluid shadow"
                              alt="icon"
                            />
                            &nbsp;&nbsp;Turnkey Interior
                          </a>
                        </li>
                        <li className="d-none">
                          <a onClick={closeDropdown}
                            href="/architectural-consultation-service-in-hyderabad"
                            className="dropdown-item"
                          >
                            <img
                              src={slider4icon}
                              className="img-fluid shadow"
                              alt="icon"
                            />
                            &nbsp;&nbsp;Architectural Consultation
                          </a>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li className="nav-item">
                  <span className='nav-link' >
                    <a  href="/portfolio">
                      Portfolio
                    </a>
                    </span>
                  </li>
                  <li className="nav-item">
                    <span className='nav-link' >
                    <a  href="/contact">
                      Contact
                    </a>
                    </span>
                  </li>
                    <li className="nav-item">
                    <span className='nav-link' >
                        <a className='but' href="appointment"> Book Consultation&nbsp;&#10509;</a>
                    </span>
                    </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}





