import React from 'react'
import { Helmet } from 'react-helmet'
import Cafe1 from './img/cafe/Enscape_1.jpg'
import Cafe2 from './img/cafe/Enscape_2.jpg'
import Cafe3 from './img/cafe/Enscape_3.jpg'
import Cafe4 from './img/cafe/Enscape_4.jpg'
import Cafe5 from './img/cafe/Enscape_5.jpg'
import Cafe6 from './img/cafe/Enscape_6.jpg'
import Cafe7 from './img/cafe/Enscape_7.jpg'

import Pro11 from './img/project-1/project-1.jpg'
import Pro12 from './img/project-1/project-2.jpg'
import Pro13 from './img/project-1/project-3.jpg'
import Pro14 from './img/project-1/project-4.jpg'

import Pro21 from './img/project-2/project-1.jpg'
import Pro22 from './img/project-2/project-2.jpg'
import Pro23 from './img/project-2/project-3.jpg'
import Pro24 from './img/project-2/project-4.jpg'
import Pro25 from './img/project-2/project-5.jpg'
import Pro26 from './img/project-2/project-6.jpg'
import Pro27 from './img/project-2/project-7.jpg'
import Pro28 from './img/project-2/project-8.jpg'
import Pro29 from './img/project-2/project-9.jpg'

import pro30 from './img/project-3/K1.jpg'
import pro31 from './img/project-3/K3.jpg'
import pro32 from './img/project-3/KR1.jpg'
import pro33 from './img/project-3/liv2.jpg'
import pro34 from './img/project-3/liv5.jpg'
import pro35 from './img/project-3/liv7.jpg'
import pro36 from './img/project-3/mb3.jpg'
import pro37 from './img/project-3/mb4.jpg'
import pro38 from './img/project-3/mb44.jpg'
import pro39 from './img/project-3/PBD3.jpg'

import pro40 from './img/project-4/1.jpg'
import pro41 from './img/project-4/2.jpg'
import pro42 from './img/project-4/3.jpg'
import pro43 from './img/project-4/4.jpg'
import pro44 from './img/project-4/5.jpg'
import pro45 from './img/project-4/6.jpg'
import pro46 from './img/project-4/7.jpg'
import pro47 from './img/project-4/8.jpg'
import pro48 from './img/project-4/9.jpg'
import pro49 from './img/project-4/10.jpg'
import pro50 from './img/project-4/11.jpg'
import pro51 from './img/project-4/12.jpg'




export default function Portfolio() {
  return (
    <>
    <Helmet>
        <title>2bhk Interior Design Cost In Hyderabad | 2 Bhk Interior Design Cost In Hyderabad</title>
        <meta name="Description" content="Wondering about the 2BHK, 3bhk, 4bhk, 5 bhk interior design cost in Hyderabad. At A3G Interio, we offer transparent pricing with no hidden charges, ensuring you get the best value for your investment."/>
        <meta name="Keywords" content="2bhk interior design cost in hyderabad, 2 bhk interior design cost in hyderabad,commercial interior designers in hyderabad, top interiors in hyderabad,luxury interior designers in hyderabad,good interior designers in hyderabad,interior design cost in hyderabad,interior designers in hyderabad india,3d interior designers in hyderabad"/>
        <meta http-equiv="cleartype" content="on" />
        <meta name="author" content="A3G Interio" />
        <meta name="https://www.a3ginterio.com" CONTENT="2 Bhk Interior Design Cost In Hyderabad" />
        <meta name="rating" CONTENT="General" />
        <meta name="robots" content="index,follow,noarchive" />
        <meta name="revisit-after" CONTENT="daily" />
        <meta content="English" name="language" />
        <meta content="index,follow" name="robots" />
        <link href="https://www.a3ginterio.com/opensearch.xml" rel="search" title="2bhk Interior Design Cost In Hyderabad | 2 Bhk Interior Design Cost In Hyderabad" type="application/opensearchdescription+xml" />
        <link rel="canonical" href="https://www.a3ginterio.com/portfolio"/>
        <meta property="og:locale" content="en_US"/>
        <meta property="og:type" content="website"/>
        <meta property="og:title" content="2bhk Interior Design Cost In Hyderabad | 2 Bhk Interior Design Cost In Hyderabad"/>
        <meta property="og:description" content="Wondering about the 2BHK, 3bhk, 4bhk, 5 bhk interior design cost in Hyderabad. At A3G Interio, we offer transparent pricing with no hidden charges, ensuring you get the best value for your investment."/>
        <meta property="og:url" content="https://www.a3ginterio.com/portfolio"/>
        <meta property="og:site_name" content="2bhk Interior Design Cost In Hyderabad | 2 Bhk Interior Design Cost In Hyderabad"/>

    </Helmet>
    <section className='bg-primary-1 d-none'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <h1 className='text-white'>Our Portfolio</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-6">
            <img src="" alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>
    <section className='py-5 bg-light mt-5'>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mb-4">
                    <h2 data-aos="fade-up">Our Portfolio</h2>
                    {/* <p data-aos="fade-up">We have done quality projects in variety of sector around the globe.</p> */}
                </div>
            </div>
            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Cafe</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Cafe1} alt="" className="img-fluid rounded-3" />
                    </figure>
                    
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 1</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe2} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 2</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe3} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 3</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe4} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 4</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img  src={Cafe5} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 5</h4>
                    </div> */}
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe6} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 6</h4>
                    </div> */}
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Cafe7} alt="" className="img-fluid rounded-3" /></figure>
                    {/* <div className="bannerText2 p-3" >                        
                        <h4 data-aos="fade-up" className='text-white text-center'>Project Name 6</h4>
                    </div> */}
                </div>
                {/* <div className="col-md-12  justify-content-center text-center">
                    <a href="#"><button className="custom-btn btn-12"><span>Load More !</span><span>View More !</span></button></a>
                </div> */}
            </div>
            

            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 1</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Pro11} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro12} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro13} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro14} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 
            </div>
            
            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 2</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={Pro21} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro22} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro23} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro24} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro25} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro26} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro27} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro28} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={Pro29} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 
            </div>




            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 3</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={pro30} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro31} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro32} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro33} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro34} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro35} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro36} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro37} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro38} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro39} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>


            </div>


            <div className='row'>
                <div className="col-md-12">
                    <h3 className='text-danger'>Project 4</h3>
                    <hr></hr>
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure>
                        <img src={pro40} alt="" className="img-fluid rounded-3" />
                    </figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro41} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>
                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro42} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro43} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro44} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro45} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro46} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro47} alt="" className="img-fluid rounded-3" /></figure>                    
                </div> 

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro48} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro49} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro50} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>

                <div className='col-md-4 position-relative mb-5 hover15'>
                    <figure><img src={pro51} alt="" className="img-fluid rounded-3" /></figure>                    
                </div>


            </div>
        </div>
    </section>
    </>
  )
}
