import './App.css';
import React, { useState, useEffect } from "react";
import{BrowserRouter, Routes, Route} from 'react-router-dom';

import Navbar from './components/Navbar';
import SliderSec from './components/SliderSec';


import ContactForm from './components/ContactForm';
import Footer from './components/Footer';

import PrivacyPolicy from './components/PrivacyPolicy';
import Terms from './components/Terms';
import Contact from './components/Contact';
import About from './components/About';


import PopupForm from "./components/PopupForm";



import ServiceSlider from './components/ServiceSlider';
import HowWeWork from './components/HowWeWork';

import RecentPost from './components/RecentPost';

import ContactUs from './components/ContactUs';
import Testimonial from './components/Testimonial';
import DesignPhilosphy from './components/DesignPhilosphy';





import Appointment from './components/Appointment';
import Portfolio from './components/Portfolio';
import WhyChooseUs from './components/WhyChooseUs';
import AboutUs from './components/About/AboutUs';
import Residential from './components/residentInterior/Residential';
import Commercial from './components/commercial/Commercial';
import Turnkey from './components/Turnkey/Turnkey';
import Architectural from './components/architectural/Architectural';








function App() {

   // State to track if the popup should be shown
   const [showPopup, setShowPopup] = useState(false);

   


  return (
      
      <BrowserRouter>     
        <Navbar/>
        
        <Routes>
          <Route exact path='/' element={
            <>
                      
                <SliderSec/>                    
              
                <About></About>
              
              <ServiceSlider />
             
              <HowWeWork />
              <DesignPhilosphy></DesignPhilosphy>
              <WhyChooseUs></WhyChooseUs>

              
              
              
              
              
            
              
              <ContactUs />
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3807.0671167404207!2d78.42772507421007!3d17.408566402196563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb977cc5c24ae7%3A0x5b19340319a63c78!2sA3G%20INTERIO%20%3A%20Interior%20Designers%20and%20Architects!5e0!3m2!1sen!2sin!4v1727200806499!5m2!1sen!2sin" width="100%" height="350"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              
              
            </>
          } />
          <Route path='portfolio' element={
            <>
              <Portfolio></Portfolio>
            </>
          } />


          <Route path='about-us' element={
            <>
              <AboutUs></AboutUs>
            </>
          } />

          <Route path='residential-interior-service-in-hyderabad' element={
            <>
              <Residential></Residential>
              <ServiceSlider />
             
              
              <WhyChooseUs></WhyChooseUs>
              <ContactUs />
            </>
          } />

          <Route path='commercial-interior-service-in-hyderabad' element={
            <>
              <Commercial></Commercial>
              <ServiceSlider />
             
              
              <WhyChooseUs></WhyChooseUs>
              <ContactUs />
            </>
          } />

          <Route path='turnkey-interior-solutions-in-hyderabad' element={
            <>
              <Turnkey></Turnkey>
              <ServiceSlider />
             
              <HowWeWork />
              <WhyChooseUs></WhyChooseUs>
              <ContactUs />
            </>
          } />

          <Route path='architectural-consultation-service-in-hyderabad' element={
            <>
              <Architectural></Architectural>
              <ServiceSlider />
             
              <HowWeWork />
              <WhyChooseUs></WhyChooseUs>
              <ContactUs />
            </>
          } />


          

          

           

         

             

          <Route path='privacy-policy' element={
            <>
              <section className='bg-about'>
                <PrivacyPolicy/>
              </section>
            </>
          } />

          <Route path='terms-of-use' element={
            <>
              <section className='bg-about'>
                <Terms/>
              </section>
            </>
          } /> 

          <Route path='contact' element={
            <>             
              <Contact/>    
            </>
          } />

          
          
          
             

          <Route path='appointment' element={
            <>
              <Appointment></Appointment>
            </>
          } />     
          
          
          
        </Routes>
        
        <PopupForm show={showPopup} closePopup={() => setShowPopup(false)} />
        
          
       </BrowserRouter> 
  );
}

export default App;
