import React, { useState } from 'react';
import ContactForm from './ContactForm'
import { Helmet } from 'react-helmet';
import callIcon from './img/icons8-headset.gif'
import mapIcon from './img/icons8-map-marker.gif'
import time from './img/icons8-schedule.gif'
import email from './img/icons8-email.gif'
import abi from './img/book-an-appointment.png'


export default function Appointment() {
  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
  <Helmet>
    <title>Best Interior Decorators In Hyderabad | Kitchen Interior Design Hyderabad</title>
    <meta name="Description" content="Specializing in Kitchen Interior Design in Hyderabad, we create elegant and practical kitchen spaces that inspire culinary brilliance.Whether you desire a sleek modern kitchen, a cozy traditional setup."/>
    <meta name="Keywords" content="best interior decorators in hyderabad,kitchen interior design hyderabad, office interior design hyderabad,interior design services hyderabad,home interior design cost in hyderabad,interior design price in hyderabad,best interior design company in hyderabad, top interior companies in hyderabad"/>
    <meta http-equiv="cleartype" content="on" />
    <meta name="author" content="A3G Interio" />
    <meta name="https://www.a3ginterio.com" CONTENT="Kitchen Interior Design Hyderabad" />
    <meta name="rating" CONTENT="General" />
    <meta name="robots" content="index,follow,noarchive" />
    <meta name="revisit-after" CONTENT="daily" />
    <meta content="English" name="language" />
    <meta content="index,follow" name="robots" />
    <link href="https://www.a3ginterio.com/opensearch.xml" rel="search" title="Best Interior Decorators In Hyderabad | Kitchen Interior Design Hyderabad" type="application/opensearchdescription+xml" />
    <link rel="canonical" href="https://www.a3ginterio.com/appointment"/>
    <meta property="og:locale" content="en_US"/>
    <meta property="og:type" content="website"/>
    <meta property="og:title" content="Best Interior Decorators In Hyderabad | Kitchen Interior Design Hyderabad"/>
    <meta property="og:description" content="Specializing in Kitchen Interior Design in Hyderabad, we create elegant and practical kitchen spaces that inspire culinary brilliance.Whether you desire a sleek modern kitchen, a cozy traditional setup."/>
    <meta property="og:url" content="https://www.a3ginterio.com/appointment"/>
    <meta property="og:site_name" content="Best Interior Decorators In Hyderabad | Kitchen Interior Design Hyderabad"/>
  </Helmet>
   
  <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-8">
            <h1 className='text-white'>Book An Appointment</h1>
            <h4>Transforming Your Vision into Reality</h4>
            <p>Feel Free to get in touch with us.</p>
          </div>
          <div className="col-md-4">
            <img src={abi} alt="img" className='img-fluid' />
          </div>
        </div>
      </div>
    </section>

    <section className='py-5' id='contactPage'>
      <div className="container">
        <div className="row">

          <div className="col-md-3 position-relative">
              
              <div className="address text-center shadow">
                <h5 className='h6'><img src={callIcon} alt="" className="img-fluid" /></h5>
                
                <h6 className='pt-5 main-color fw-bold pb-2'>Office Phone Number</h6>
                <p><a href='tel: +91 7075 90 1914' className='text-dark text-decoration-none'>+91 7075 90 1914</a></p>               
                
              </div>
            
          </div>

          <div className="col-md-6 position-relative">
            <div className="address text-center shadow">
              <h5><img src={mapIcon} alt="" className="img-fluid" /> Office</h5>  
              <h6 className='pt-5 main-color fw-bold pb-2'>Hyderabad</h6>
              <p>H.No.8-2-622/4, Road No.10, Banjara hills, Hyderabad- 500 034, Hyderabad, Telangana 500034</p>
                         
               
            </div>
          </div>

          
          <div className="col-md-3 position-relative">
           
              <div className="address email text-center shadow">
                <h5 className='h6 '><img src={email} alt="" className="img-fluid" /></h5>
                
                <h6 className='pt-5 main-color fw-bold pb-2'>Mail for information</h6>
                <p><a href='mailto: info@a3ginterio.com' className='text-dark text-decoration-none'>info@a3ginterio.com</a></p>
                
                
              </div>
            
          </div>
        </div>
      </div>
    </section>

    <ContactForm></ContactForm>
    
    </>
  )
}

