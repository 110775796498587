import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import slider1 from './img/resi.jpg'
import slider1icon from './img/service1-1-bg.png'

import slider2 from './img/commercial.jpg'
import slider2icon from './img/service1-2-bg.png'

import slider3 from './img/renovation.jpg'
import slider3icon from './img/service1-3-bg.png'

import slider4 from './img/design-consultancy.jpg'
import slider4icon from './img/service1-4-bg.png'

const ResponsiveAutoSlider = ({ items }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4);

  const handleResize = () => {
    if (window.innerWidth >= 1200) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 992) {
      setVisibleItems(3);
    } else if (window.innerWidth >= 768) {
      setVisibleItems(2);
    } else {
      setVisibleItems(1);
    }
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % Math.ceil(items.length / visibleItems));
    }, 5000);

    return () => clearInterval(interval);
  }, [items.length, visibleItems]);

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };

  const getItemsForSlide = (slideIndex) => {
    let slideItems = [];
    for (let i = 0; i < visibleItems; i++) {
      const index = (slideIndex * visibleItems + i) % items.length;
      slideItems.push(items[index]);
    }
    return slideItems;
  };

  const totalSlides = Math.ceil(items.length / visibleItems);

  return (
    <Carousel
      activeIndex={currentIndex}
      onSelect={handleSelect}
      indicators={true}
      controls={true}
      interval={null}
    >
      {Array.from({ length: totalSlides }).map((_, idx) => (
        <Carousel.Item key={idx}>
          <div className="d-flex justify-content-center">
            {getItemsForSlide(idx).map((item, subIdx) => (
              <div className="p-2" key={subIdx}>
                {item}
              </div>
            ))}
          </div>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};


const App = () => {
  const items = [
    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider1} alt="" className="img-fluid w-100 sliderImg" />
      </div>
      <img src={slider1icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text text-center">        
        <h5 data-aos="fade-up" className='text-center'>Residential Interior</h5>
        <p data-aos="fade-up" className='text-center'>Create a unique living space that reflects your personal...</p>
        <Link to="/residential-interior-service-in-hyderabad"  className="btn btn-primary" >Know More</Link>        
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider2} alt="" className="img-fluid w-100 sliderImg" />
      </div>
      <img data-aos="fade-up" src={slider2icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text text-center">        
        <h5 data-aos="fade-up" className='text-center'>Commercial Interior</h5>
        <p data-aos="fade-up" className='text-center'>Maximize productivity with strategically...</p>
        <Link to="/commercial-interior-service-in-hyderabad"  className="btn btn-primary" >Know More</Link>
        
      </div>
    </div>,

    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider3} alt="" className="img-fluid  w-100 sliderImg" />
      </div>
      <img  data-aos="fade-up"  src={slider3icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text text-center">        
        <h5  data-aos="fade-up" className='text-center'>Turnkey Interior</h5>
        <p  data-aos="fade-up" className='text-center'>From concept to completion, we handle...</p>
        <Link to="/turnkey-interior-solutions-in-hyderabad"  className="btn btn-primary" >Know More</Link>
        
      </div>
    </div>,  
    
    
    <div className="slider-item position-relative">
      <div className="slider-img">
        <img data-aos="fade-up" src={slider4} alt="" className="img-fluid w-100 sliderImg" />
      </div>
      <img data-aos="fade-up" src={slider4icon} alt="" className="img-fluid slider-icon" />
      <div className="slider-text text-center">        
        <h5 data-aos="fade-up" className='text-center'>Architectural Consultation</h5>
        <p data-aos="fade-up" className='text-center'>Collaborate with our principal architects to...</p>
        <Link to="/architectural-consultation-service-in-hyderabad"  className="btn btn-primary" >Know More</Link>
        
      </div>
    </div>,
  ];

  return (
    <>
    <section className='services pb-5 pt-5 mb-5 bg-light'>
      <div className='container'>
        <div className="row">     
          <div className="App">
            <h6 data-aos="fade-up" className='text-center text-uppercase'>Service We Provide</h6>
            <h2 data-aos="fade-up" className='text-center fw-bold pb-4 text-uppercase'>Explore Our <span className='fw-normal primary-text'>Services</span></h2>
            <ResponsiveAutoSlider items={items} />
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default App;
