import React from 'react'
import { Helmet } from "react-helmet";
import sliderImg from './img/slider/LIVING-ROOM-OPT-2.jpg'
import sliderImg2 from './img/slider/dining-room-1212.jpg'
import sliderImg3 from './img/slider/slider-3.jpg'
import sliderImg4 from './img/slider/sofa-informal-room-12.jpg'
import sliderImg5 from './img/slider/wardrobe-pot-2.jpg'

export default function SliderSec() {
  return (
        <>       
            <Helmet>
                <title>Interior Designer In Hyderabad | Best Home Interior Designers In Hyderabad</title>
                <meta name="Description" content="A3G Interio is your ultimate destination for transforming your house into a stunning, functional masterpiece. Renowned as a top interior designer in Hyderabad."/>
                <meta name="Keywords" content="interior designer in hyderabad,best home interior designers in hyderabad,interior designer in hyderabad,commercial interior designer in hyderabad,best home,interior designers in hyderabad,best interior designers in hyderabad,interiors in hyderabad,interior design hyderabad,home interiors in hyderabad"/>
                <meta http-equiv="cleartype" content="on" />
                <meta name="author" content="A3G Interio" />
                <meta name="https://www.a3ginterio.com" CONTENT="Interior Designer In Hyderabad" />
                <meta name="rating" CONTENT="General" />
                <meta name="robots" content="index,follow,noarchive" />
                <meta name="revisit-after" CONTENT="daily" />
                <meta content="English" name="language" />
                <meta content="index,follow" name="robots" />
                <link href="https://www.a3ginterio.com/opensearch.xml" rel="search" title="Interior Designer In Hyderabad | Best Home Interior Designers In Hyderabad" type="application/opensearchdescription+xml" />
                <link rel="canonical" href="https://www.a3ginterio.com"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Interior Designer In Hyderabad | Best Home Interior Designers In Hyderabad"/>
                <meta property="og:description" content="A3G Interio is your ultimate destination for transforming your house into a stunning, functional masterpiece. Renowned as a top interior designer in Hyderabad."/>
                <meta property="og:url" content="https://www.a3ginterio.com/"/>
                <meta property="og:site_name" content="Interior Designer In Hyderabad | Best Home Interior Designers In Hyderabad"/>
            </Helmet>
           
        
    
        <div id="carouselExampleFade" className="carousel slide carousel-fade w-100" data-bs-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active">
                    <img src={sliderImg} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-bold'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>
                <div className="carousel-item">
                    <img src={sliderImg2} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-bold'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>

                <div className="carousel-item">
                    <img src={sliderImg3} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-bold'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>


                <div className="carousel-item">
                    <img src={sliderImg4} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-bold'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>


                <div className="carousel-item">
                    <img src={sliderImg5} className="d-block w-100" alt="..." />
                    <div className="carousel-caption  d-md-block">
                        <h2 className='fw-bold'>Transforming Spaces, Creating Experiences </h2>
                        <a><button className='btn btn-primary1 h5'>Our Portfolio</button></a>&nbsp;&nbsp;&nbsp;
                        <a><button className='btn btn-primary1 h5'>Get a Quote</button></a>
                    </div>
                </div>                
            </div>
            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleFade" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button>
        </div>
    </>
    
  )
}
