import React from 'react'
import { Helmet } from "react-helmet";
import home from '../img/home.png'
import support from '../img/easy.png'
import easy from '../img/space-plan.png'
import trust from '../img/hospitality.png'
export default function AboutUs() {
  return (
    <>
    <Helmet>
      <title>Home Interior Design Hyderabad | Best Interior Design In Hyderabad</title>
      <meta name="Description" content="A3G Interio, your ultimate destination for Home Interior Design in Hyderabad. We specialize in creating stunning and functional interiors that resonate with your lifestyle and preferences. "/>
      <meta name="Keywords" content="home interior design hyderabad, best interior design in hyderabad,best interior designers in hyderabad with price,top 10 interior designers in hyderabad,budget interior designers in hyderabad,best interiors in hyderabad,top interior designers in hyderabad,2bhk interior design cost in hyderabad,best budget interior designers in hyderabad"/>
      <meta http-equiv="cleartype" content="on" />
      <meta name="author" content="A3G Interio" />
      <meta name="https://www.a3ginterio.com" CONTENT="Best Interior Design In Hyderabad" />
      <meta name="rating" CONTENT="General" />
      <meta name="robots" content="index,follow,noarchive" />
      <meta name="revisit-after" CONTENT="daily" />
      <meta content="English" name="language" />
      <meta content="index,follow" name="robots" />
      <link href="https://www.a3ginterio.com/opensearch.xml" rel="search" title="Home Interior Design Hyderabad | Best Interior Design In Hyderabad" type="application/opensearchdescription+xml" />
      <link rel="canonical" href="https://www.a3ginterio.com/about-us"/>
      <meta property="og:locale" content="en_US"/>
      <meta property="og:type" content="website"/>
      <meta property="og:title" content="Home Interior Design Hyderabad | Best Interior Design In Hyderabad"/>
      <meta property="og:description" content="A3G Interio, your ultimate destination for Home Interior Design in Hyderabad. We specialize in creating stunning and functional interiors that resonate with your lifestyle and preferences. "/>
      <meta property="og:url" content="https://www.a3ginterio.com/about-us"/>
      <meta property="og:site_name" content="Home Interior Design Hyderabad | Best Interior Design In Hyderabad"/>
    </Helmet>
    <section className='bg-primary-1'>
      <div className="container">
        <div className="row align-items-center py-5">
          <div className="col-md-12 text-center py-5">
            <h1 className='text-white'>About</h1>
            <h4>Transforming Your Vision into Reality</h4>
          </div>
          <div className="col-md-6">
            {/* <img src={abi} alt="img" className='img-fluid' /> */}
          </div>
        </div>
      </div>
    </section>

    <section className='aboutt'>
      <div className="container py-5">
        <div className="row align-items-center justify-content-center position-relative">        
            
       
          <div className="col-md-10 home-text text-center">
              <h2 className="fw-meduim py-3">About <span className='main-color fw-normal'>A3G INTERIO</span> </h2>
              {/* <h5 className="text-uppercase mt-0 main-color">Transforming Your Vision into Reality</h5> */}
              
              <h2 className="h5 fw-normal pt-3">Welcome to A3G Interio, your trusted partner in innovative interior design solutions for both residential and commercial spaces.</h2>
              <h3 className="h6 fw-normal pt-3">At A3G Interio, we believe every space tells a story, and we are dedicated to crafting spaces that are nothing short of timeless masterpieces. From the intimate warmth of a cozy home to the vibrant energy of a dynamic commercial setup, we are committed to turning your vision into a reality. Our team of passionate architects and designers works tirelessly to ensure each project embodies elegance, functionality, and cutting-edge innovation, creating environments that inspire and captivate.</h3>
              <h3 className="h6 fw-normal pt-3">We combine expertise with creativity to deliver designs that not only look stunning but are also highly practical and sustainable. Our approach is grounded in a deep understanding of design trends and a commitment to tailoring each space to meet the unique needs and preferences of our clients.</h3>
              <h6 className='h6 fw-normal pt-3'>What sets us apart is our collaborative approach. By partnering with talented and aspiring students from Lakhotia College of Design, we bring a fresh perspective to every project. This blend of youthful creativity and seasoned experience creates a synergy that enables us to achieve extraordinary results. Each design is crafted with meticulous attention to detail, where innovation meets functionality, and aesthetics blend seamlessly with purpose.</h6>
              <h6 className='h6 fw-normal pt-3'>At A3G Interio, we don’t just design interiors – we create environments that inspire, uplift, and transform the way you live, work, and connect with your surroundings. Let us bring your dream space to life, where every detail reflects your individuality and every corner radiates perfection. <b>A3G Interio – Transforming Spaces, Transforming Lives.</b></h6>
          </div>
          <div className="col-md-6">
                                         
                
              
          </div>            
        </div>   
        </div>  
    </section>

    <section className='ab-page'>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center">
            <h3 className='main-color'>What we offer</h3>
            <h5 className='h5 fw-normal'>Residential Design: From contemporary homes to luxurious apartments, we create spaces that are functional, beautiful, and personalized to suit your lifestyle.</h5>
          </div>
        </div>

        <div className='row py-4'>
          <div className='col-md-3'>
            <div className='p-3 shadow mb-4'>
              <img src={home} alt="" className='img-fluid' />
              <h5 className='main-color'>Residential Design</h5>
              <p className=''>From contemporary homes to luxurious apartments, we create spaces that are functional, beautiful, and personalized to suit your lifestyle.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow mb-4'>
              <img src={easy} alt="" className='img-fluid' />
              <h5 className='main-color'>Commercial Design</h5>
              <p>Our commercial design solutions are crafted to enhance the aesthetics, functionality, and brand image of your business, whether it’s an office, retail space, or hospitality venue.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow mb-4'>
              <img src={trust} alt="" className='img-fluid' />
              <h5 className='main-color'>Custom Solutions</h5>
              <p>We take a bespoke approach to each project, working closely with you to understand your vision and translate it into stunning, practical interiors.</p>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='p-3 shadow mb-4'>
              <img src={support} alt="" className='img-fluid' />
              <h5 className='main-color'>Profit-Sharing Model</h5>
              <p>Our unique business model ensures that every member of our design team, from seasoned architects to budding student designers, shares in the success of the project. This approach fosters a collaborative, motivated environment that drives exceptional results.</p>
            </div>
          </div>

          
        </div>

        <div className="row justify-content-center py-4">
          <div className="col-md-12">
            <h3 className='main-color pb-2 pt-3'>Our Philosophy</h3>
            <h4 className='fw-normal'>We believe in designing spaces that not only look good but also improve the way you live and work. Our approach is rooted in a deep understanding of your needs, combined with a passion for design excellence. Each project is a blend of creativity, innovation, and precision, ensuring a harmonious balance between form and function.</h4>
          </div>
        </div>

        
      </div>
    </section>
    </>
  )
}
